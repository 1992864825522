import React, { useEffect, useRef, useState, Suspense, lazy } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faPlug } from '@fortawesome/pro-solid-svg-icons/faPlug';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';

import { debounce, isExternalUrl, removeSpecialCharacters } from 'libs/content';
import { clearActiveListItem } from 'components/map/helpers';
import Container, { Filters } from 'components/map/Container';
import Heading from 'libs/heading';
import LazyImage from 'components/LazyImage';
import Link from 'components/Link';
import InputField from 'components/forms/InputField';
import MultiSelectField from 'components/forms/MultiSelectField';
import List from 'components/map/List';
import Loading from 'components/Loading';

const Map = lazy(() => import('components/map/Map.js'));

//#region Styling
const MapWrapper = styled(Map)`
	position: relative;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	${p =>
		p.theme.media.large(css`
			height: 100% !important;
		`)}
`;

const SearchClear = styled.button`
	background-color: transparent;
	border: 0;
	margin-left: auto;
	svg {
		width: 17px;
		height: 26px;
		color: ${p => p.theme.colors.grey700};
	}
	&:hover svg {
		color: ${p => p.theme.colors.blue600};
	}
`;

//#endregion

export default function MapComponent({
	title = 'Våre elektrikeravdelinger',
	departments,
	...props
}) {
	const [searching, setSearching] = useState(false);
	const [results, setResults] = useState(departments);
	const [selectedAreas, setSelectedAreas] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const mapRef = useRef(null);
	const searchInputRef = useRef(null);

	library.add(faPlug);
	dom.watch();

	/**
	 * Clear the search field
	 * @returns {void}
	 **/
	function handleClear() {
		setSearchTerm('');
		searchInputRef.current.value = '';
		setSelectedAreas([]);
		setSearching(false);
		setResults(departments);
		clearActiveListItem();
	}

	/**
	 * Function to handle search, debounced to avoid multiple calls
	 * @param {string} value - The value to search for
	 * @returns {void}
	 **/
	const handleSearch = debounce(e => {
		if (
			e?.target?.value?.length === 0 ||
			(!isNaN(e?.target?.value) && e?.target?.value?.length < 3)
		) {
			handleClear();
			return;
		}

		// If value is numbers, make sure it has 4 digits, otherwise 3
		const charLimit = isNaN(e?.target?.value) ? 3 : 4;

		if (e?.target?.value?.length < charLimit) return;

		setSearching(true);
		setSearchTerm(e?.target?.value);
	}, 500);

	/**
	 * Function to add or remove an item from an array
	 * @param {Array} array - The array to add or remove the item from
	 * @param {any} item - The item to add or remove
	 * @returns {Array} - The updated array
	 */
	function toggleArrayItem(array, item) {
		const index = array.indexOf(item);
		if (index > -1) {
			return array.filter(i => i !== item);
		} else {
			return [...array, item];
		}
	}

	useEffect(() => {
		if (!departments?.length || !searching) {
			return;
		}

		// Filter the departments based on the search term (title or places)
		const matchesSearchTerm = dep => {
			if (!searchTerm) return true;

			const placesMatch = dep?.places
				?.toLowerCase()
				?.includes(searchTerm?.toLowerCase());

			const foundZipMatch = findZipMatch(dep?.zips?.zipcodes, searchTerm);

			const foundParentWithZipMatch = dep?.relations?.some(r =>
				findZipMatch(r?.zips?.zipcodes, searchTerm)
			);

			return (
				placesMatch ||
				foundZipMatch ||
				foundParentWithZipMatch ||
				dep?.title
					?.toLowerCase()
					?.includes(searchTerm?.toLowerCase()) ||
				dep?.relations?.some(r =>
					r?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())
				)
			);
		};

		// Filter the departments based on the selected areas
		const matchesArea = dep => {
			if (selectedAreas.length === 0) return true;

			const selectedPlaces = selectedAreas.flatMap(area =>
				area
					.split(' ')
					.map(place => place?.replace(',', '').trim().toLowerCase())
			);

			const regex = new RegExp(
				selectedPlaces?.filter(s => s !== 'og').join('|'),
				'i'
			);

			const foundMatch = selectedAreas.includes(dep?.title);

			const foundMatchingParent =
				!dep?.parent &&
				dep?.relations
					?.map(r => r?.title?.toLowerCase())
					?.some(rel => regex.test(rel.toLowerCase()));

			return foundMatch || foundMatchingParent;
		};

		// Filter the departments based on the search term and selected areas
		const res = departments.filter(
			s => matchesSearchTerm(s) && matchesArea(s)
		);

		setResults(res);
		setSearching(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searching, departments?.length, searchTerm, selectedAreas]);

	return (
		<Container
			map={
				<Suspense fallback={<Loading text="Laster kart ..." />}>
					<MapWrapper
						ref={mapRef}
						source="departments"
						icon="fa-solid fa-plug"
						cluster={false}
						iconBg={true}
						markers={results?.map(entry => {
							return {
								...entry,
								id: removeSpecialCharacters(
									entry?.parent?.title
										? `${entry?.title}--${entry?.parent?.title}`
										: entry?.title
								),

								title:
									entry?.titleInMap ||
									(entry?.parent?.title && entry?.title) ||
									`NTE Elektro ${entry?.title}`,
								markerSize: !!entry?.parent?.title
									? 'small'
									: 'large',
								intro:
									(entry?.parent?.title &&
										`Tilhører ${entry?.parent?.title}`) ||
									entry?.places,
								link: {
									slug: `/${entry?.page?.slug}`,
								},
								internalLink: true,
								linkText: 'Se ansatte og kontaktinformasjon',
							};
						})}
						popupLinkClick={props => {
							if (!props?.link) return;
							if (isExternalUrl(props?.link)) {
								window.open(props?.link, '_blank');
							} else {
								navigate(props?.link);
							}
						}}
					/>
				</Suspense>
			}
			rightContent={
				<div className="list elektro-departments-list">
					{title && (
						<Heading
							level={props?.headinglevel || 'h2'}
							className="h2">
							{title}
						</Heading>
					)}
					<Filters
						perRow={4}
						items={[
							<MultiSelectField
								id="department-select"
								name="department-select"
								options={[
									...new Set(
										departments
											?.map(s => s?.title)
											.filter(c => c)
											.flat()
									),
								].sort()}
								data-cy="departments-filter__select"
								loading={searching}
								placeholder="Velg område"
								label="Velg område"
								selected={selectedAreas}
								onChange={(_, val) => {
									if (!val || !val?.length > 0) return;
									setSearching(true);

									const updatedDepartments = toggleArrayItem(
										selectedAreas,
										val
									);

									setSelectedAreas(updatedDepartments);
								}}
							/>,
							<InputField
								id="departments-search"
								name="departments-search"
								label="Søk med stedsnavn eller postnummer"
								data-cy="departments-filter__search"
								type="search"
								ref={searchInputRef}
								onChange={e => handleSearch(e)}
								leadingIcon={true}
								loading={searching}
								clear={
									(!searching && searchTerm && (
										<SearchClear
											onClick={handleClear}
											title="Klikk for å tømme søket">
											<FontAwesomeIcon
												icon={faCircleXmark}
											/>
										</SearchClear>
									)) ||
									null
								}
								icon={faSearch}
								{...props}
							/>,
						]}
					/>
					{(results?.length > 0 && (
						<List
							items={results
								?.filter(r => !r?.parent?.title)
								?.map(entry => (
									<Department {...entry} />
								))}
						/>
					)) || <p>Ingen resultater på din filtering ...</p>}
				</div>
			}
		/>
	);
}

/**
 * Function to find a match for a zip code in comma separated ranges or single zip codes
 * @param {Object} zipcodes - The string to check
 * @param {string} zip - The zip code to match
 * @returns {Array} - The departments that match the zip code
 */
function findZipMatch(zipcodes, zip) {
	if (!zip || !zipcodes) return false;

	const zipRanges = zipcodes?.split(',');

	return zipRanges.some(zipRange => {
		if (zipRange.includes('-')) {
			const [start, end] = zipRange.split('-').map(Number);
			return parseInt(zip) >= start && parseInt(zip) <= end;
		} else {
			return parseInt(zip) === parseInt(zipRange, 10);
		}
	});
}

const InnerWrap = styled.div`
	${p =>
		p.theme.media.smallUp(css`
			display: flex;
			gap: ${p => p.theme.spacing.desktop.small};
			flex-direction: row-reverse;
		`)}
`;

const Info = styled.div`
	flex-grow: 1;
	h3 {
		margin-bottom: 0;
	}
	> :last-child {
		margin-bottom: 0;
	}
`;

const Image = styled.div`
	flex: 0 0 192px;
	margin-bottom: ${p => p.theme.spacing.desktop.xsmall};
	${p =>
		p.theme.media.smallUp(css`
			margin-bottom: 0;
		`)}
	img {
		width: 100%;
		height: auto;
		border-radius: ${p => p.theme.utils.borderRadius};
	}
`;

const Placeholder = styled.div`
	width: 100%;
	aspect-ratio: 16 / 9;
	background-color: ${p => p.theme.colors.blue200};
	border-radius: ${p => p.theme.utils.borderRadius};
`;

const Places = styled.p`
	margin: 5px 0 0;
`;

const ReadMore = styled(Link)`
	margin-top: 10px;
	display: inline-block;
`;

/**
 * Department component to display a single department
 * @param {Object} props - The props for the component
 * @param {string} props.title - The title of the department
 * @param {string} props.slug - The slug of the department
 * @param {array} props.places - The places within the department
 * @param {string} props.text - The text of the department
 * @param {Object} props.link - The link to the department
 * @param {Object} props.image - The image for the department
 * @returns {JSX.Element} - The department component
 */
function Department({ ...props }) {
	return (
		<InnerWrap id={removeSpecialCharacters(props?.title)}>
			<Image>
				{(props?.image && (
					<LazyImage
						ratio="16:10"
						width={192}
						height={120}
						{...props?.image}
					/>
				)) || <Placeholder />}
			</Image>
			<Info>
				<Heading level="h3" className="h4">
					{props?.titleInMap || `NTE Elektro ${props?.title}`}
				</Heading>
				{props?.places && <Places>{props?.places}</Places>}
				{props?.page?.slug && (
					<ReadMore
						to={props?.page?.slug}
						title={
							props?.titleInMap || `NTE Elektro ${props?.title}`
						}>
						Se ansatte og kontaktinformasjon
					</ReadMore>
				)}
			</Info>
		</InnerWrap>
	);
}
