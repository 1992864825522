import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import MapComponent from './MapComponent';

export default function DepartmentsMap({
	title = 'Våre elektrikeravdelinger',
	...props
}) {
	const departmentsData = useStaticQuery(departmentsQuery);
	const departments = departmentsData?.departments?.nodes || [];

	if (!departments.length) return null;

	return (
		<div className="elektro-departments-map" {...props}>
			<MapComponent
				title={title}
				departments={departments}
				headinglevel={props?.headinglevel}
			/>
		</div>
	);
}

/**
 * GraphQL query to get Elektro departments (Struktur - Avdeling) from Contentful
 * @returns {Object} - The departments data
 */
const departmentsQuery = graphql`
	query departments {
		departments: allContentfulStrukturAvdeling(
			filter: { showInDepartmentsMap: { eq: true } }
			sort: { navn: ASC }
		) {
			nodes {
				title: navn
				places
				zips {
					zipcodes: zips
				}
				location: adresse {
					lat
					lon
				}
				parent: parentDepartment {
					title: navn
					places
					zips {
						zipcodes: zips
					}
				}
				relations: struktur___avdeling {
					title: navn
					zips {
						zipcodes: zips
					}
					parent: parentDepartment {
						title: navn
					}
				}
				page: departmentPage {
					title: tittel
					slug
				}
				titleInMap
				image: bilde {
					file {
						url
					}
					width
					height
					description
				}
			}
		}
	}
`;
